* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  text-decoration: none;
  scroll-behavior: smooth;
}

body {
  background-color: #fff;
}

.btn {
  font-size: 1.2rem;
  color: #72b2dd;
}

.section {
  overflow: hidden;
  width: 100%;
  padding: 20px;

  h2 {
    text-align: center;
    color: #304770ea;
    font-size: 2rem;
  }
  h3 {
    font-size: 1.4rem;
    color: #222;
  }
  h4 {
    color: #444;
  }
}

.border {
  width: 60%;
  margin: 30px auto;
  height: 1px;
  background-color: #86ef823d;
}

/* ---------------MEDIA QUERIES--------------- */

/* TABLET */

@media only screen and (min-width: 700px) {
}

/* DESKTOP */

@media only screen and (min-width: 1024px) {
  .section {
    h2 {
      font-size: 1.6rem;
    }
    h3 {
      font-size: 1.2rem;
    }
  }
}
