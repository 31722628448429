.about {
  width: 100%;
  padding: 20px;
  line-height: 1.6;
  text-align: center;
  img {
    width: 100%;
    border-radius: 15px;
  }
  h3 {
    color: #304770ea;
  }
  .mission-vision-content {
    text-align: center;
    div {
      margin: 15px 0;
      i {
        font-size: 1.6em;
        color: #304770ea;
      }
      h3 {
        margin-top: -10px;
        color: #304770ea;
      }
      p {
        font-size: 0.9rem;
        color: #444;
      }
    }
  }
  .staff {
    width: 100%;
    margin: 20px 0;
    .staff-member {
      margin: 5px 0 10px;
      display: flex;
      flex-direction: column;
      text-align: center;

      width: 100%;
      display: flex;
      background-color: #f0f2f569;
      padding: 10px;
      border-radius: 15px;

      img {
        margin: 0 auto;
        width: 6rem;
        height: 6rem;
        border-radius: 50%;
      }

      h6 {
        width: 60%;
        margin: 0 auto 10px;
        color: #304770ea;
        font-weight: 600;
        font-size: 1.1rem;
        line-height: 1.3rem;
      }
      p {
        font-size: 0.9rem;
      }

      .staff-member-bottom {
        text-align: center;
      }
    }
  }
}
/* ---------------MEDIA QUERIES--------------- */

/* TABLET */

@media only screen and (min-width: 700px) {
  .about {
    width: 100%;
    padding: 20px 15%;
    line-height: 1.6;
    text-align: center;

    .staff {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

/* DESKTOP */

@media only screen and (min-width: 1024px) {
  .about {
    width: 100%;
    padding: 20px 20%;
    line-height: 1.6;
    text-align: center;
    .about-grid {
      width: 90%;
      margin: 5px auto;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
      img {
        width: 100%;
        border-radius: 15px;
      }
      p {
        text-align: left;
      }
    }
    .mission-vision-content {
      margin: 20px 0;
      text-align: center;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
    }
    .staff {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
