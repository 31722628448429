footer {
  padding: 15px;
  width: 100%;
  text-align: center;
  background: linear-gradient(#304770f6, #304770ea);
  img {
    width: 6rem;
    margin: 30px auto;
  }
  .footer-container {
    padding: 20px 5px;
    h3 {
      font-size: 1.5rem;
      color: #fff;
      margin-bottom: 10px;
    }
    .footer-social-links {
      font-size: 1.6rem;
      color: #bbb;
      margin-right: 10px;
    }
    ul {
      font-size: 1.1rem;
      list-style: none;

      li {
        margin-bottom: 10px;
        color: #fff;
        font-weight: 400;
        i {
          font-size: 1.1rem;
          margin-right: 6px;
          color: #ffff;
        }
        .footer-links {
          color: #fff;
        }
      }
    }
  }
  h6 {
    margin-top: 15px;
    text-align: center;
    font-size: 1rem;
    font-weight: 400;
    color: #bbb;
  }
  h5 {
    margin-top: 5px;
    width: 100%;
    font-weight: 400;
    text-align: center;
    font-size: 0.8rem;
    color: #bbb;
  }
}

/* ---------------MEDIA QUERIES--------------- */

/* TABLET */

@media only screen and (min-width: 600px) {
  footer {
    img {
      width: 25%;
      margin: 30px auto;
    }
    .footer-container {
      h3 {
        font-size: 1.6rem;
      }
      .footer-social-links {
        font-size: 1.7rem;
      }
      ul {
        font-size: 1.3rem;

        li {
          i {
            font-size: 1.2rem;
          }
        }
      }
    }
    h6 {
      font-size: 1.1rem;
    }
    h5 {
      font-size: 0.9rem;
    }
  }
}

/* DESKTOP */

@media only screen and (min-width: 1024px) {
  footer {
    .footer-top {
      text-align: center;
      width: 70%;
      margin: 0 auto;
      padding: 10px 30px;
      display: grid;
      grid-template-columns: 1.5fr 2.5fr 2.5fr;
    }
    img {
      width: 80%;
      margin: 30px auto;
      object-fit: contain;
    }
    .footer-container {
      h3 {
        font-size: 1rem;
      }
      .footer-social-links {
        font-size: 1rem;
      }
      ul {
        font-size: 0.7rem;

        li {
          a {
            font-size: 0.8rem;
          }
          i {
            font-size: 1rem;
          }
          span {
            margin: 0;
            font-size: 0.8rem;
            font-weight: 400;
          }
        }
      }
    }
    h6 {
      font-size: 0.8rem;
    }
    h5 {
      font-size: 0.7rem;
    }
  }
}
