.privacy-terms {
  text-align: left;
  h2 {
    color: #304770ea;
    margin-bottom: 20px;
  }
  p {
    font-size: 1.1rem;
    margin-bottom: 10px;
    color: #444;
  }
  ul {
    margin: 20px 0;
    list-style: none;
    li {
      margin: 10px 0 20px;
      h3 {
        font-size: 1.2rem;
        font-weight: 500;
        margin-bottom: 5px;
        color: #304770ea;
      }
      p {
        font-size: 1rem;
        color: #676767;
      }
      .links {
        color: #304770ea;
      }
    }
  }
}

/* ---------------MEDIA QUERIES--------------- */

/* TABLET */

@media only screen and (min-width: 767px) {
  .privacy-terms {
    p {
      font-size: 1.2rem;
    }
    ul {
      li {
        h3 {
          font-size: 1.3rem;
        }
        p {
          margin-left: 15px;
          font-size: 1.2rem;
        }
      }
    }
  }
}

/* DESKTOP */

@media only screen and (min-width: 1024px) {
}
