.main {
  width: 100%;
  position: relative;
  img {
    width: 100%;
  }
  .main-info {
    position: absolute;
    right: 2%;
    bottom: 2vh;
    width: 70%;
    h1 {
      width: fit-content;
      font-size: 2.2rem;
      font-weight: 800;
      color: #fff;
      line-height: 35px;
      display: inline-block;
    }
    p {
      color: #d7e8fd;
      font-weight: 500;
      font-size: 1rem;
    }
  }
}

/* ---------------MEDIA QUERIES--------------- */
/* TABLET */

@media only screen and (min-width: 550px) {
  .main {
    width: 100%;
    height: 50vh;
    position: relative;
    img {
      width: 100%;
      object-fit: cover;
      height: 100%;
    }
    .main-info {
      text-align: right;
      position: absolute;
      right: 5%;
      bottom: 5vh;
      width: 70%;
    }
  }
}

/* TABLET HORIZONTAL */

@media only screen and (min-width: 700px) {
  .main {
    width: 100%;
    height: 50vh;
    position: relative;
    img {
      width: 100%;
      object-fit: cover;
      height: 100%;
    }
    .main-info {
      position: absolute;
      right: 5%;
      bottom: 5vh;
      width: 70%;
      h1 {
        font-size: 2.6rem;
      }
      p {
        font-size: 1.2rem;
      }
    }
  }
}

/* DESKTOP */

@media only screen and (min-width: 1024px) {
  .main {
    width: 100%;
    height: 60vh;
    position: relative;

    .main-info {
      position: absolute;
      right: 10%;
      bottom: 10vh;
      width: 70%;
      h1 {
        font-size: 2.6rem;
      }
      p {
        font-size: 1.2rem;
      }
    }
  }
}
