.image-grid {
  margin: 20px 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 5px;
  .gallery-item {
    cursor: pointer;
    overflow: hidden;
    img {
      width: 100%;
      height: auto;
      border-radius: 8px;
      transition: transform 0.3s ease-in-out;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}

/* MODAL GALLERY */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.844);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  .modal-content {
    position: relative;
    width: 90%;

    img {
      width: 100%;
      height: auto;
      border-radius: 8px;
    }
  }
  .prev-button,
  .next-button {
    position: absolute;
    background: #304770ea;
    height: 2.6rem;
    width: 2.6rem;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;

    border-radius: 50%;
    font-size: 1.3rem;
    z-index: 10;
    &:hover {
      background: rgba(255, 255, 255, 0.8);
      color: #304770ea;
    }
  }

  .close-button {
    position: absolute;
    background: none;
    border: none;

    color: #fff;
    font-size: 2.3rem;
  }

  .close-button {
    top: 5px;
    right: 10px;
  }

  .prev-button {
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  .next-button {
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}

/* ---------------MEDIA QUERIES--------------- */

/* TABLET */

@media only screen and (min-width: 700px) {
}

/* DESKTOP */

@media only screen and (min-width: 1024px) {
}
