.contact-links {
  width: 100%;
  padding: 20px;
  list-style: none;
  li {
    margin: 15px auto;
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: #e8e8e8ea;
    padding: 15px 20px;
    border-radius: 15px;
    i {
      font-size: 1.6rem;
      color: #304770ea;
    }
    a {
      color: #304770ea;
    }
    p {
      span {
        font-size: 0.8rem;

        font-weight: 500;
      }
    }

    span {
      font-size: 0.9rem;
    }
  }
}
.faq-box {
  width: 100%;
  padding: 20px;
  .faq-item {
    margin: 5px auto;
    background-color: #e8e8e8ea;
    padding: 15px 20px;
    border-radius: 15px;
    .faq-question {
      h4 {
        width: 100%;
        font-size: 1.2rem;
        display: flex;
        justify-content: space-between;
        font-weight: 600;
      }
    }
    .faq-answer {
      margin-top: 10px;
      color: #444;
    }
  }
}

/* ---------------MEDIA QUERIES--------------- */

/* TABLET */

@media only screen and (min-width: 600px) {
  .contact-links {
    display: grid;
    width: 100%;
    padding: 20px 10%;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 5px;

    li {
      width: 100%;
      margin: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  .faq-box {
    width: 100%;
    margin: 10px auto;
    padding: 20px 10%;
  }
}

/* DESKTOP */

@media only screen and (min-width: 850px) {
  .contact-links {
    display: grid;
    width: 100%;
    padding: 20px 10%;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 5px;
    li {
      padding: 10px 20px;
      border-radius: 15px;
    }
  }
  .faq-box {
    width: 100%;
    margin: 10px auto;
    padding: 20px 20%;
  }
}

/* DESKTOP */

@media only screen and (min-width: 1024px) {
  .contact-links {
    display: grid;
    width: 90%;
    margin: 20px auto;
    padding: 20px 20%;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 5px;
    li {
      i {
        font-size: 0.9rem;
      }
      font-size: 0.8rem;
    }
  }
  .faq-box {
    width: 50%;
    margin: 10px auto;
    padding: 20px;
    .faq-item {
      .faq-question {
        h4 {
          font-size: 1rem;
        }
      }
      .faq-answer {
        font-size: 0.9rem;
      }
    }
  }
}
