.about-home {
  width: 100%;
  margin: 5px 0 30px;
  padding: 20px 0;
  position: relative;
  text-align: center;

  &::before {
    content: "";
    background-color: #304770ea;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -20%;
    right: 0;
    width: 150%;
    transform: rotate(-5deg);
    z-index: 1;
  }
  .about-home-box {
    position: relative;
    padding: 20px;

    z-index: 2;

    img {
      width: 100%;
      margin: 10px auto;
      border-radius: 15px;
    }
    p {
      z-index: 2;
      font-size: 1rem;
      text-align: left;
      color: #fff;
      margin-bottom: 15px;
    }
  }
}

/* REVIEWS HOME */
.reviews-container {
  margin: 20px 0;
  width: 100%;
  padding: 40px 20px;
  position: relative;
  text-align: center;
  h3 {
    position: relative;
    color: #fff;
    z-index: 2;
  }
  &::before {
    content: "";
    background-color: #304770ea;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -20%;
    right: 0;
    width: 150%;
    transform: rotate(-5deg);
    z-index: 1;
  }
  .review-card {
    margin: 20px 0;
    position: relative;
    z-index: 2;
    text-align: center;
    img {
      width: 3rem;
      border-radius: 50%;
    }
    .review-rating {
      color: #f3ee71ea;
      margin-bottom: 2px;
    }
    h4 {
      color: #fff;
      font-size: 1.3rem;
    }
    p {
      font-size: 1rem;
      color: #ddd;
    }
  }
}
/* STATS HOME */
.stats-home {
  width: 100%;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 10px;
  .stats-card {
    text-align: center;
    .stats-icon {
      color: #304770ea;
    }
  }
}
/* GALLERY HOME */
.gallery-home {
  text-align: center;
  margin: -5px -20px -50px;
  overflow: hidden;
  position: relative;

  .slider-gallery-container {
    position: relative;
    width: 150%;
    height: 250px;
    overflow: hidden;
    position: relative;
    margin: 30px 0 25px;
    .slider-gallery {
      display: flex;
      align-items: center;
      animation: scroll 30s linear infinite alternate;

      width: calc(200%);
      height: 250px;
      img {
        height: 100%;
        flex-shrink: 0;
        transition: all 0.3s ease;

        &:hover {
          filter: grayscale(0);
        }
      }
      &::after {
        z-index: 2;
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 300%;
        height: 100%;
        background: linear-gradient(#30477020, #3047708b, #3047706d);
      }
      @keyframes scroll {
        from {
          transform: translateX(0);
        }
        to {
          transform: translateX(-150%);
        }
      }
    }
  }
}

/* ---------------MEDIA QUERIES--------------- */

/* TABLET */

@media only screen and (min-width: 550px) {
  .about-home {
    .about-home-box {
      width: 70%;
      margin: 0px auto;
      padding: 15px;
    }
  }

  /* REVIEWS HOME */
  .reviews-container {
    margin: 20px 0 30px;
    width: 100%;
    padding: 40px 15%;
    .review-card {
      margin: 20px 30px;
      img {
        width: 3.5rem;
      }
    }
  }
  /* STATS HOME */
  .stats-home {
    width: 80%;
    margin: 10px auto;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
}

/* TABLET HORIZONTAL */

@media only screen and (min-width: 800px) {
  .about-home {
    .about-home-box {
      width: 80%;
      margin: 0px auto;

      div {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
        img {
          height: 80%;
        }
      }
    }
  }

  /* REVIEWS HOME */
  .reviews-container {
    margin: 20px 0 30px;
    width: 100%;

    padding: 40px 0;
    .reviews-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      .review-card {
        margin: 20px 30px;
        img {
          width: 3.5rem;
        }
        p {
          font-size: 0.9rem;
        }
      }
    }
  }
  /* STATS HOME */
  .stats-home {
    width: 90%;
    margin: 10px auto;
    grid-template-columns: repeat(4, 1fr);
    gap: 5px;
  }

  /* GALLERY HOME */
  .gallery-home {
    .slider-gallery-container {
      height: 200px;
      .slider-gallery {
        @keyframes scroll {
          from {
            transform: translateX(0);
          }
          to {
            transform: translateX(-100%);
          }
        }
      }
    }
  }
}

/* DESKTOP */

@media only screen and (min-width: 1024px) {
  .about-home {
    .about-home-box {
      width: 50%;
      margin: 0px auto;
      padding: 10px 20px;
      div {
        margin: 15px auto 25px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
        p {
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        img {
          height: 100%;
        }
      }
    }
  }

  /* REVIEWS HOME */
  .reviews-container {
    margin: 20px 0 30px;
    width: 100%;

    padding: 40px 15%;
    .reviews-grid {
      .review-card {
        margin: 20px 15px;
        h4 {
          color: #fff;
          font-size: 1.1rem;
        }
        p {
          font-size: 0.8rem;
          color: #ddd;
        }
      }
    }
  }
  /* STATS HOME */
  .stats-home {
    width: 60%;
    margin: 10px auto;
    grid-template-columns: repeat(4, 1fr);
    gap: 5px;
    .stats-card {
      width: 100%;
      font-size: 0.8rem;
    }
  }
}
