.programs {
  width: 90%;
  margin: 15px auto;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  .programs-box {
    margin: 10px 0;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    height: 250px;
    width: 100%;
    padding: 0;
    text-decoration: none;
    img {
      width: 100%;
    }
    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 4px;
      width: 100%;
      height: 100%;
      background-color: #000000a2;
    }
    .programs-box-info {
      padding: 15px;
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 2;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      left: 0%;
      top: 0%;

      h3 {
        padding: 0px 10px;
        color: #fff;
        font-size: 1.8rem;
        margin-bottom: 10px;
      }
      .programs-icons {
        padding: 5px 0px 15px;
        gap: 5px;
        font-size: 1.4rem;
        color: #72b2dd;
        display: flex;
        align-items: center;
        justify-content: start;
      }
      p {
        width: 80%;
        margin-top: 5px;
        font-size: 0.8rem;
        color: #ccc;
      }
    }
  }
}

/* PROGRAMI PAGE */

.programs-page {
  width: 90%;
  margin: 15px auto;
  padding: 20px;
  .program-info {
    margin: 20px 0;
    .program-top {
      .program-img {
        position: relative;
        width: 100%;
        border-radius: 30px;
        overflow: hidden;
        img {
          width: 100%;
        }
        div {
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          height: 100%;
          width: 100%;
          background-color: #000000a2;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          color: #fff;
          padding: 20px;

          h2 {
            width: 100%;
            text-align: center;
            font-size: 1.9rem;
          }
          span {
            color: #72b2dd;
            font-size: 1.4rem;
            font-weight: 600;
          }
        }
      }
      .programs-grid {
        padding: 10px 20px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        .programs-box {
          p {
            margin: 15px 0;
          }
          i {
            color: #72b2dd;
            margin-right: 5px;
          }
        }
      }
    }
    .programs-bottom {
      .form {
        margin: 5px 0;
        label {
          display: inline-block;
          width: 100%;
          margin: 5px 0;
        }
        option,
        input,
        select {
          padding: 15px 20px;
          border-radius: 15px;
          background-color: #f0f2f569;
        }
      }
      .programs-btn {
        margin-top: 25px 0 15px;
        display: inline-block;
        padding: 15px 20px;
        border-radius: 15px;
        color: #fff;
        font-weight: 600;
        background-color: #72b2dd;
      }
      margin: 15px 0;
      h4 {
        margin-top: 15px;
        color: #72b2dd;
      }
    }
  }
}

/* ---------------MEDIA QUERIES--------------- */

/* TABLET */

@media only screen and (min-width: 550px) {
  .programs {
    padding: 0px 15%;
  }
  .programs-page {
    width: 70%;
    margin: 15px auto;
    padding: 20px;
  }
}

/* TABLET HORIZONTAL */

@media only screen and (min-width: 700px) {
  .programs {
    width: 80%;

    padding: 0;
    margin: 15px auto 45px;
    grid-template-columns: repeat(2, 1fr);

    gap: 10px;
    .programs-box {
      height: 280px;
      margin: 0;
      img {
        height: 100%;
      }
    }
  }
  .programs-page {
    width: 90%;
    margin: 15px auto;
    padding: 20px;
    .program-info {
      .program-top {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        margin: 20px 0;
        img {
          height: 100%;
        }
      }
    }
  }
}

/* DESKTOP */

@media only screen and (min-width: 1024px) {
  .programs {
    width: 70%;
    padding: 0;
    margin: 15px auto 45px;
    grid-template-columns: repeat(4, 1fr);

    gap: 10px;
    .programs-box {
      height: 280px;
      margin: 0;

      img {
        height: 100%;
      }
      .programs-box-info {
        h3 {
          font-size: 1.2rem;
        }
        p {
          font-size: 0.6rem;
        }
        .programs-icons {
          font-size: 0.9rem;
        }
      }
    }
  }
  .programs-page {
    width: 70%;
    margin: 15px auto;
    padding: 20px;
    display: grid;
    gap: 15px;

    grid-template-columns: repeat(2, 1fr);
    .program-info {
      border-radius: 15px;
      padding: 20px;
      background-color: #f3f3f39a;
      gap: 20px;
      margin: 20px 0;
    }
  }
}
